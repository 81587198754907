<template>
  <div class="home">
    <button
      class="button field is-info is-light"
      @click="isCardModalActive = true"
    >
      <b-icon icon="plus"></b-icon>
      <span>Nova taxa</span>
    </button>
    <b-table
      :data="allTaxes"
      ref="table"
      paginated
      per-page="10"
      detailed
      detail-key="id"
      :striped="true"
      :debounce-search="1000"
      @details-open="(row) => $buefy.toast.open(`Visualizando os detalhes`)"
      :show-detail-icon="true"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
    >
      <b-table-column field="id" label="ID" width="40" numeric v-slot="props">{{
        props.row.id
      }}</b-table-column>

      <b-table-column
        field="name"
        label="Nome"
        :searchable="true"
        sortable
        v-slot="props"
      >
        <template v-if="showDetailIcon">{{ props.row.name }}</template>
        <template v-else>
          <a @click="props.toggleDetails(props.row)">{{ props.row.name }}</a>
        </template>
      </b-table-column>

      <b-table-column
        field="value"
        label="Porcentagem"
        :searchable="true"
        sortable
        v-slot="props"
        >{{ props.row.value }}%</b-table-column
      >

      <b-table-column
        field="customer"
        label="Cliente"
        sortable
        :searchable="true"
        v-slot="props"
        >{{ props.row.customer.name }}</b-table-column
      >

      <b-table-column v-slot="props" label="Opções">
        <button
          class="button is-small is-light"
          @click.prevent="
            onEdit(props.row)
            isCardModalActive = true
          "
        >
          <b-icon icon="pen" size="is-small"></b-icon>
        </button>
        <button
          class="button is-small is-danger"
          @click.prevent="onDelete(props.row.id)"
        >
          <b-icon icon="delete" size="is-small"></b-icon>
        </button>
      </b-table-column>

      <template slot="detail" slot-scope="props">
        <article class="media">
          <div class="media-content">
            <div class="content">
              <p>
                <strong>{{ props.row.name }}</strong>
                <br />
                <strong>Porcentagem:</strong>
                <small>{{ props.row.value }}%</small>
                <br />
                <span v-if="props.row.start_time">
                  <strong>Horário de início:</strong>
                  <small>{{ props.row.start_time }}</small>
                </span>
                <br />
                <span v-if="props.row.end_time">
                  <strong>Horário de término:</strong>
                  <small>{{ props.row.end_time }}</small>
                </span>
                <br />
                <strong>Cliente:</strong>
                <small>{{ props.row.customer.name }}</small>
              </p>
            </div>
          </div>
        </article>
      </template>
    </b-table>

    <b-modal v-model="isCardModalActive" :width="640" scroll="keep">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <b-field label="Apelido *">
              <b-input v-model="model.name" required></b-input>
            </b-field>
            <b-field label="Porcentagem *">
              <b-input type="number" v-model="model.value" required></b-input>
            </b-field>
            <b-field label="Horário de início">
              <b-timepicker
                rounded
                placeholder="Selecione o horário de início"
                icon="clock"
                hour-format="24"
                v-model="model.start_time"
                locale="pt-BR"
              >
              </b-timepicker>
            </b-field>
            <b-field label="Horário de término">
              <b-timepicker
                rounded
                placeholder="Selecione o horário de término"
                icon="clock"
                hour-format="24"
                v-model="model.end_time"
                locale="pt-BR"
              >
              </b-timepicker>
            </b-field>
            <b-field label="Cliente *">
              <b-select
                placeholder="Selecione um cliente"
                required
                expanded
                v-model="model.customer_id"
              >
                <option
                  v-for="(item, index) in allCustomers"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </b-select>
            </b-field>
            <b-button type="button field is-info" class="mt-10" @click="save"
              >Salvar</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import service from '../features/taxes/store/service'
import Tax from '../models/tax'

export default {
  name: 'Taxes',
  data() {
    return {
      model: Tax.model,
      isModalVisible: false,
      showDetailIcon: false,
      isCardModalActive: false,
    }
  },
  created() {
    this.init()
  },
  computed: {
    ...mapState('taxes', ['allTaxes']),
    ...mapState('customers', ['allCustomers']),
  },
  methods: {
    ...mapActions('taxes', ['getAllTaxes']),
    ...mapActions('customers', ['getAllCustomers']),
    async init() {
      await this.getAllTaxes()
      await this.getAllCustomers()
    },
    async save() {
      const tax = { ...this.model }

      if (tax.start_time) {
        tax.start_time = this.$moment(tax.start_time).format('HH:mm')
      }

      if (tax.end_time) {
        tax.end_time = this.$moment(tax.end_time).format('HH:mm')
      }

      try {
        const save = tax.id
          ? await service.updateTax(tax)
          : await service.saveTax(tax)

        await this.$success('Taxa')
        await location.reload(true)
      } catch (error) {
        console.log('error', error)
        this.$error(
          error.response.data.message || error.response.data[0].message
        )
      }
    },
    async onDelete(id) {
      try {
        await service.deleteTax(id)

        await this.$delete('Taxa')
        location.reload(true)
      } catch (error) {
        this.$error(error.response.data.message)
      }
    },
    onEdit(item) {
      this.model = { ...item }

      if (this.model.start_time) {
        const startTime = new Date()
        startTime.setHours(this.model.start_time.split(':')[0])
        startTime.setMinutes(this.model.start_time.split(':')[1])
        this.model.start_time = startTime
      }

      if (this.model.end_time) {
        const endTime = new Date()
        endTime.setHours(this.model.end_time.split(':')[0])
        endTime.setMinutes(this.model.end_time.split(':')[1])
        this.model.end_time = endTime
      }
    },
    showModal() {
      this.isModalVisible = true
    },
    closeModal() {
      this.isModalVisible = false
      this.model = {}
    },
  },
}
</script>

<style scoped></style>
